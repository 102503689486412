import {
  AfterViewInit, Component, ElementRef,
  HostListener, OnDestroy, OnInit
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContentDetail } from "@app/core/models/interfaces/content-detail";
import { IResponseCardViewModel } from "@app/core/models/interfaces/i-response-card-view-model";

import { validateExpiredCard } from "@app/shared/helpers/validate-expired-card.helper";
import { ContentDetailService } from "@app/shared/services/content-detail.service";
import { ContentV3Service } from "@app/shared/services/content-v3.service";
import { UserStateService } from "@app/shared/services/user-state.service";
import { WalletV3Service } from "@app/shared/services/wallet-v3.service";
import { ModalManagementService } from "@app/shared/utils/modal-management.service";
import { MarketingParamsService } from "@app/shared/utils/marketing-params.service";
import { SeoService } from "@app/shared/utils/seo.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FeatureFlagService } from "@app/shared/utils/feature-flag.service";


@Component({
  selector: "app-content-detail-modal",
  templateUrl: "./content-detail-modal.component.html",
  styleUrls: ["./content-detail-modal.component.scss"],
})
export class ContentDetailModalComponent
implements OnInit, OnDestroy, AfterViewInit
{
  contentId: string;
  contentType: string;
  config = null;
  private locationChangeListener: () => void;


  loadingSkeleton = false;
  contentDetail: ContentDetail;
  canOpenCheckout = false;
  onlyInfo = "false";
  isPpvWatch = false;
  marketingParams: any = {};

  constructor(
    private contentDetailService: ContentDetailService,
    private modalManagementService: ModalManagementService,
    private marketingParamsService: MarketingParamsService,
    private featureFlagService: FeatureFlagService,
    private contentService: ContentV3Service,
    private walletService: WalletV3Service,
    private authService: UserStateService,
    private activeModal: NgbActiveModal,
    private el: ElementRef,
    private router: Router,
    private seoService: SeoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.marketingParamsService.sendMarketingTrack({
      event_location: 'more-info'
    });
    this.getContentDetail();
    this.getUserCreditCards();
    this.getMarketingParamsWithAction();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event): void {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.seoService.resetSEO()
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkIfModalExceedsViewport();
    }, 200);
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.checkIfModalExceedsViewport();
  }

  get isParentPpv(): boolean {
    return this.contentDetail.contentPpv.isParentPpv;
  }

  get NewWatchEnable(): boolean {
    return this.featureFlagService.getFeatureStatus();
  }

  getContentDetail(): void {
    this.loadingSkeleton = true;
    this.contentDetailService.getContentDetail(this.contentId).subscribe(
      (result) => {
        this.contentDetail = result.value;
        this.loadingSkeleton = false;
        this.updateService();
      },
      () => {
        this.closeModal(true);
      }
    );
  }

  updateService(): void {
    this.seoService.updateSEO({
      title: this.contentDetail.title,
      description: this.contentDetail.description,
      tags: this.contentDetail?.tags,
      topic: this.contentDetail?.topic?.value,
    });
  }

  getUserCreditCards(): void {
    if (this.isUserLoggedIn()) {
      this.fetchUserCreditCards();
    }
  }

  validateIsUser(contentType: string, contentId: string): boolean {
    const getContentType = contentType || this.contentType;
    const getContentId = contentId || this.contentId;
    return this.valideteIsUserLoggedIn(getContentType, getContentId);
  }

  redirectToContent(): void {
    if (!this.validateIsUser(this.contentType, this.contentId)) {
      return;
    }

    const contentIsPPV = this.contentDetail.contentPpv !== null;
    if (contentIsPPV) {
      return;
    }

    switch (this.contentType) {
      case 'program':
        this.redirectToProgram();
      break;
      case 'video':
        this.navigate('watch', this.contentId);
      break;
      default:
        this.navigate(this.contentType, this.contentId);
    }

    this.closeModal();
  }

  redirectToProgram(): void {
    const lastSeenEpisode = this.contentDetail.lastSeenEpisode;
    const episodes = this.contentDetail.seasonEpisodes.items;

    if (lastSeenEpisode) {
      if (this.NewWatchEnable) {
        this.navigate('watch', lastSeenEpisode.videoId)
      } else {
        this.navigate('video', lastSeenEpisode.videoId);
      }
    } else if (episodes.length > 0) {
      if (this.NewWatchEnable) {
        this.navigate('watch', episodes[0].videoId)
      } else{
        this.navigate('video', episodes[0].videoId);
      }
    }
  }

  redirectToFree(): void {
    if (this.validateIsUser(this.contentType, this.contentId)) {
      this.contentService.contentAcquire(this.contentId).subscribe((res) => {
        this.navigate(this.contentType, this.contentId);
      });
      this.closeModal();
    }
  }

  navigate(contentType: string, contentId: string): void {
    this.router.navigate(["/", contentType, contentId], {queryParams: this.marketingParams});
  }

  rentAction(contentType: string = "", contentId: string = ""): void {
    const getContentType = contentType || this.contentType;
    const getContentId = contentId || this.contentId;
    if (this.validateIsUser(getContentType, getContentId)) {
      this.checkoutModalData(true, getContentType);
    }
  }

  acquireAction(contentType: string, contentId: string): void {
    const getContentType = contentType || this.contentType;
    const getContentId = contentId || this.contentId;
    if (this.validateIsUser(getContentType, getContentId)) {
      this.checkoutModalData(false, getContentType);
    }
  }

  rentProgram({contentType, contentId}): void {
    contentType = this.onlyInfo? 'video' : contentType;
    this.rentAction(contentType, contentId);
  }

  purcharseProgram({contentType, contentId}): void {
    contentType = this.onlyInfo? 'video' : contentType;
    this.acquireAction(contentType, contentId);
  }

  valideteIsUserLoggedIn(contentType: string, contentId: string): boolean {
    if (!this.isUserLoggedIn()) {
      const getContentType = contentType || this.contentType;
      const getContentId = contentId || this.contentId;
      this.modalManagementService
      .openModalLogin(getContentType, getContentId)
      .catch((result) => {
        result && this.activeModal.close();
      });
      return false;
    }
    return true;
  }

  checkoutModalData(isRent: boolean, contentType: string): void {
    if (this.canOpenCheckout) {
      this.modalManagementService
      .openModalCheckout({
        content: this.contentDetail,
        contentType: contentType,
        isRent,
        parentModal: this.activeModal,
        isParentPPV: this.contentDetail.contentPpv.isParentPpv,
      })
      .then(() => this.activeModal.dismiss())
      .catch(() => this.activeModal.dismiss());
    } else {
      this.navigateToContent(isRent ? "rent" : "purchase");
    }
  }

  navigateToContent(actionType: string, contentType: string = ""): void {
    this.getMarketingParamsWithAction(actionType);


    if (this.isParentPpv) {
      contentType = 'program';
      this.contentId = this.contentDetail?.program.id;
    }
    this.router.navigate(["/acquire", contentType || this.contentType, this.contentId], { queryParams: this.marketingParams});
    this.activeModal.close();
  }

 closeModal(canHandleResult: boolean = false, closeBoth: boolean = false): void {
    if(!this.isPpvWatch){
    this.executeAnimateBottom(canHandleResult, closeBoth);
    }
  }

  checkIfModalExceedsViewport(): void {
    this.modalManagementService.checkIfModalExceedsViewport(this.el);
  }

  private isUserLoggedIn(): boolean {
    return this.authService.isUserLoggedIn;
  }

  private fetchUserCreditCards(): void {
    this.walletService
    .getUserCreditCards()
    .subscribe((creditCards) => this.handleUserCreditCards(creditCards));
  }

  private handleUserCreditCards(creditCards: IResponseCardViewModel[]): void {
    if (creditCards.length > 0) {
      const validatedCards = creditCards.map(validateExpiredCard);
      const allCardsValid = validatedCards.every((card) => !card.expired);

      if (allCardsValid) {
        this.canOpenCheckout = true;
      }
    }
  }

  private executeAnimateBottom(canHandleResult: boolean, closeBoth: boolean = false): void {
    if(!closeBoth){
      const params = {
        contentId: this.config?.contentId,
        contentType: this.config?.contentType
      }
      const callBack = this.config && this.config.contentId ? this.openModalDetail.bind(this, params) : null;
      this.modalManagementService.executeAnimateBottom(this.activeModal, canHandleResult, callBack);
    }
    else{
      this.modalManagementService.executeAnimateBottom(this.activeModal, canHandleResult);
    }
  }

  private getMarketingParamsWithAction(actionType?: string): any {
    const marketingParams = this.marketingParamsService.getQueryParamsMarketing();
    const queryParams = actionType ? { action: actionType } : {};
    this.marketingParams = { ...marketingParams, ...queryParams };
  }

  openModalDetail({contentId, contentType}): void{
    this.modalManagementService.openModalMoreInfo(contentId, contentType);
  }

}
