<ng-container *ngIf="!(isMobile$ | async) && (breadcrumbData$ | async)?.title">
  <div class="header-breadcrumb" *ngIf="(breadcrumbData$ | async) as breadcrumb">
    <span [class]="icon" (click)="redirectHome()"></span>
    <span class="separator" [style.color]="breadcrumb.color || colorSeparatorDefault">
      <i class="iconlace iconlace-chevron-next"></i>
    </span>
      <span class="level"
        [class.cursor]="!!breadcrumb.firstLink"
        [ngClass]="{'uppercase': upperCase}"
        (click)="breadcrumb?.customClick ? breadcrumb.customClick() : redirectRoute(breadcrumb.firstLink, breadcrumb.querys)">
        {{breadcrumb.title}}
      </span>

      <ng-container *ngIf="!!breadcrumb.extras">
        <ng-container *ngFor="let item of breadcrumb.extras">
          <span class="separator" [style.color]="breadcrumb.color || colorSeparatorDefault">
            <i class="iconlace iconlace-chevron-next"></i>
          </span>
          <span
            class="level"
            [ngClass]="{'uppercase': upperCase}"
            (click)="item?.customClick ? item.customClick() : redirectRouteExtra(item.link, item?.canClick)"
            [class.cursor]="!!item.link && !!item?.canClick">
            {{item.title}}
          </span>
        </ng-container>
      </ng-container>
  </div>
</ng-container>

