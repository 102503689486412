<!-- TODO: Componente será trasladado a shared/layouts -->
<section [ngClass]="{clazz: clazz !== '', webViewFixClass: webViewFixClass !== '', 'comunityStateClass': comunityState}" class="container-fluid content-search " infiniteScroll
  class="{{ classApply ===('' || undefined) ?  'contents': classApply }}" [infiniteScrollDistance]="2"
  [scrollWindow]="true" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

  <div class="row">
    <ng-content select="[heroContent]"></ng-content>
  </div>
  <div class="row">
    <ng-content selected="app-order"></ng-content>
    <ng-content selected="[breadcrumb]"></ng-content>
    <ng-content selected="[left]"></ng-content>
  </div>
  <div [ngClass]="{'community-row': isFrom === 'community'}" class="row">
    <ng-content select="[content]"></ng-content>
  </div>

</section>
